import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/meeting"

export default {
	...commonApi(moduleName),
	getProjList: data => request("/projects", { method: "get", params: data }),
	getUsersTree: data =>
		request(`/meeting/${data.id}/user-tree`, { method: "get" }),
	setUsersTree: data =>
		request(`/meeting/${data.id}/user-tree`, { method: "put", body: data }),
	apiGetFiles: data =>
		request(`${moduleName}/${data.id}/files`, {
			method: "get"
		}),
	apiDeleteFiles: data =>
		request(`${moduleName}/${data.id}/files/${data.fileId}`, {
			method: "delete"
		}),
	apiMyMeetingList: data =>
		request("/meetings/my-own", {
			method: "get",
			params: data
		}),
	apiMyMeetingCalendarList: data =>
		request("/meetings/my-calendar", {
			method: "get",
			params: data
		})
}
