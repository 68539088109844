<template>
	<Modal
		class="done-list-modal"
		:value="visible"
		width="1200"
		:fullscreen="fullscreen"
		:scrollable="true"
		:mask-closable="false"
		:footer-hide="true"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<div slot="header" class="modal-header">
			<h2>已办列表</h2>
			<a
				href="javascript:;"
				class="fullscreen"
				@click="fullscreen = !fullscreen"
			>
				<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
					<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
					<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
				</Tooltip>
			</a>
		</div>
		<CtmsSearchBox :isShowReset="false" @onSearch="searchHandle">
			<CtmsSearchBoxItem :span="1" label="子流程筛选">
				<Select
					v-model="searchParams.process"
					:transfer="true"
					placeholder="请选择流程"
				>
					<Option :value="'all'">所有流程</Option>
					<Option :value="'single'">子流程</Option>
				</Select>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsDataGrid
			:height="tableHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		></CtmsDataGrid>
		<Approval
			:visible="approvalVisible"
			:subProcStatus="subProcStatus"
			:approveNo="approveNo"
			:projectId="projectId"
			type="done"
			@onOk="handleOk"
			@onCancel="approvalVisible = false"
			:procInstId="procInstId"
			:agencyType="1"
			:toDoId="toDoId"
		></Approval>
		<RegisterDetail
			:visible="registerVisible"
			@onCancel="registerVisible = false"
			:id="registerId"
		></RegisterDetail>
	</Modal>
</template>

<script>
import api from "@/api/workbench/workbench"
import { mapState } from "vuex"
import Approval from "@approval"
import RegisterDetail from "../RegisterDetail.vue"

const { getDoneList } = api

export default {
	name: "DoneList",
	components: {
		Approval,
		RegisterDetail
	},
	props: ["visible"],
	data() {
		return {
			loading: false,
			fullscreen: true,
			listData: [],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			columns: [
				{
					title: "项目名称",
					key: "projectName",
					nowrap: true
				},
				{
					title: "主要研究者(PI)",
					key: "piName"
				},
				{
					title: "流程名称",
					key: "procInstName"
				},
				{
					title: "审批时间",
					key: "dealedTime"
				},
				{
					title: "任务名称",
					key: "taskName"
				},
				{
					title: "审批结果",
					key: "result"
				},
				{
					title: "操作",
					key: "action",
					width: 120,
					renderButton: ({ row }) => {
						const actionArr = [
							{
								label: "审批详情",
								on: {
									click: this.handleApprovalShow
								}
							},
							{
								label: "审批详情",
								on: {
									click: this.handleRegisterDetailShow
								}
							}
						]
						if (row.taskType === "UserTask") {
							// 流程审批
							actionArr[1] = null
						} else if (row.taskType === "UserAudit") {
							// 注册审批查看
							actionArr[0] = null
						}
						return actionArr.filter(item => !!item)
					}
				}
			],
			approvalVisible: false,
			procInstId: "",
			registerVisible: false,
			registerId: "",
			projectId: "",
			approveNo: "",
			toDoId: "",
			subProcStatus: "", // 1 就是有子流程且子流程未完成的,2 表示子流程已完成,为空表示下一节点不是子流程
			searchParams: {
				process: "all"
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.initList()
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 200
		}),
		tableHeight() {
			return this.fullscreen
				? document.body.clientHeight - 180
				: this.dataGridHeight
		},
		isShenzhen() {
			return this.$store.state.environment === "shenzhenrenmin"
		}
	},
	created() {
		if (this.isShenzhen) {
			this.columns.splice(1, 0, {
				title: "项目编号",
				key: "projectApproveNo"
			})
			this.columns.splice(
				3,
				0,
				{
					title: "申办方",
					key: "sponsorName"
				},
				{
					title: "CRO",
					key: "croName"
				}
			)
			this.columns = [...this.columns]
		}
	},
	methods: {
		// 审批提交成功
		handleOk() {
			this.approvalVisible = false
			this.page.current = 1
			this.$emit("onOk")
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 初始化列表
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const paramsObj = {
					pageNum: this.page.current,
					pageSize: this.page.pageSize,
					relateSubProc: this.searchParams.process
				}
				if (this.searchParams.process === "all") {
					delete paramsObj.relateSubProc
				}
				if (this.searchParams.process === "single") {
					paramsObj.relateSubProc = true
				}
				const res = await getDoneList(paramsObj)
				if (res) {
					this.listData = res.data.list.map(item => {
						Object.assign(item, {
							status: item.endTime ? "已完成" : "进行中"
						})
						return item
					})
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		// 关闭
		onCancel() {
			this.$emit("onCancel")
			// this.fullscreen = false;
			this.searchParams.process = "all"
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 显示注册审批的详情
		handleRegisterDetailShow({ row }) {
			this.registerVisible = true
			this.registerId = row.taskId
		},
		// 查看已办详情
		handleApprovalShow({ row }) {
			this.toDoId = row.id
			this.procInstId = row.procInstId
			this.projectId = row.projectId
			this.approveNo = row.projectApproveNo
			this.subProcStatus = row.subProcStatus
			this.$nextTick(() => {
				this.approvalVisible = true
			})
		},
		searchHandle() {
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		}
	}
}
</script>

<style lang="less">
.done-list-modal {
	.modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
}
</style>
