<template>
	<div class="swiper-item">
		<div class="swiper-slide-box">
			<swiper ref="swiperItem" :options="swiperOption">
				<swiper-slide
					class="swiper-slide"
					:class="{ gray: !item.path || item.path == '#' }"
					v-for="item in swiperDataList"
					:key="`swiper_${item.name}`"
					@click="handleActiveItem"
					path
					id
					name
					icon
				>
					<div class="nav-item" @click="handleActiveItem(item)">
						<CtmsIcon
							:type="item.meta.activeIcon"
							:size="50"
							style="color: #2d8cf0"
						/>
						<span>{{ item.meta.title }}</span>
					</div>
				</swiper-slide>
			</swiper>
			<div
				v-show="swiperDataList.length > onePageslideNum"
				class="icon-arrow-left"
			>
				<svg-icon class="new-button" icon-class="icon-arrow-left" />
			</div>
			<div
				v-show="swiperDataList.length > onePageslideNum"
				class="icon-arrow-right"
			>
				<svg-icon class="new-button" icon-class="icon-arrow-right" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "MenuSwiperItem",
	data() {
		return {
			onePageslideNum: 5,
			swiperOption: {
				// 设置点击箭头
				navigation: {
					nextEl: ".icon-arrow-right",
					prevEl: ".icon-arrow-left"
				},
				// centeredSlides:true,
				slidesPerView: 5, // 显示个数
				slidesPerGroup: 5, // 单次滑动个数
				centerInsufficientSlides: false, // 不满足显示个数居中显示
				normalizeSlideIndex: true
			}
		}
	},
	props: {
		swiperDataList: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		swiper() {
			return this.$refs.swiperItem.$swiper
		}
	},
	watch: {
		swiperDataList(newValue, oldValue) {
			if (newValue.length > this.onePageslideNum) {
				this.swiper.setTransition(0) // 设定过渡的时间
				this.swiper.setTranslate(
					-newValue.length * (this.swiper.width / this.onePageslideNum)
				) // 设定位移，可以为正数
			}
		}
	},
	created() {},
	mounted() {},
	methods: {
		handleActiveItem(item) {
			this.$emit("clickItem", item)
		}
	}
}
</script>

<style lang="less" scoped>
.swiper-item {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 0 30px;
	background: #fff;
	box-sizing: border-box;
	border-radius: 5px;
}

.swiper-slide-box {
	position: relative;
	width: 100%;
}

.swiper-item /deep/ .swiper-container {
	width: calc(~"100% - 60px");
	height: 100%;
}

.swiper-item /deep/ .swiper-button-prev,
.icon-arrow-left {
	left: 0;
}

.swiper-item /deep/ .swiper-button-next,
.icon-arrow-right {
	right: 0;
}
.icon-arrow-left {
	.svg-icon {
		position: absolute;
		top: 80px;
		left: 0;
		width: 20px;
		height: 30px;
	}
}
.icon-arrow-right {
	.svg-icon {
		position: absolute;
		top: 80px;
		right: 0;
		width: 20px;
		height: 30px;
	}
}
.swiper-button-disabled.icon-arrow-left,
.swiper-button-disabled.icon-arrow-right {
	.new-button {
		opacity: 0.3;
	}
}

.swiper-item /deep/ .swiper-slide {
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;

	.nav-item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		.nav-icon {
			width: 5em;
			height: 6em;
			cursor: pointer;
		}
		span {
			margin-top: 8px;
			text-align: center;
			font-size: 16px;
			color: #333;
			font-weight: 600;
			cursor: default;
		}
	}
	.new-button {
		width: 5em;
		height: 6em;
		background: none;
	}
}

.gray {
	filter: grayscale(100%);
}

.custom-button-prev:before {
	top: 7px;
	transform: rotate(-50deg);
}

.custom-button-prev:after {
	top: 20px;
	transform: rotate(-130deg);
}

.custom-button-next:before {
	top: 20px;
	transform: rotate(-50deg);
}

.custom-button-next:after {
	top: 7px;
	transform: rotate(-130deg);
}
</style>
