<template>
	<div class="nav-container" v-if="isShowPage">
		<el-scrollbar class="scrollbars">
			<div class="containerMain">
				<div class="containerHeaders">
					<ContainerHeader></ContainerHeader>
				</div>

				<div class="containers">
					<div class="bannerRow">
						<div class="swiper-left">
							<MenuSwiperItem
								:swiper-data-list="centers"
								@clickItem="handleClick"
							/>
						</div>
						<message></message>
					</div>

					<div class="main">
						<div class="mines">
							<div class="firstLine">
								<!-- 资讯中心 -->
								<div class="myMessage mine-main">
									<div class="mine-div">
										<h3 class="title">
											<svg-icon class="icon" icon-class="myMessage"></svg-icon
											>资讯中心
										</h3>
										<router-link to="/resource/notice" class="more"
											>更多
										</router-link>
									</div>
									<p class="lines"></p>
									<div class="listDatas-disabled" v-if="datasDisabledN">
										暂无数据
									</div>
									<div v-else class="listDatas">
										<div
											class="list"
											v-for="(item, index) in noticeList"
											:key="index"
										>
											<div class="list-state" style="margin-right: 10px">
												<status :status="item.type" />
											</div>
											<div class="list-data" style="margin-right: 10px">
												<span
													style="color: #2d8cf0"
													@click="handleNoticeViewShow(item)"
													>{{ item.title }}</span
												>
											</div>
											<div class="list-data" style="width: 180px">
												<span>{{ item.createdTime }}</span>
											</div>
										</div>
									</div>
								</div>
								<!-- 标准操作规程 -->
								<div class="followUp mine-main">
									<div class="mine-div">
										<h3 class="title">
											<svg-icon class="icon" icon-class="followUp"></svg-icon
											>标准操作规程
										</h3>
										<router-link to="/resource/common" class="more"
											>更多
										</router-link>
									</div>
									<p class="lines"></p>
									<div class="listDatas-disabled" v-if="datasDisabledD">
										暂无数据
									</div>
									<div v-else class="listDatas">
										<div
											class="list"
											v-for="(item, index) in docList"
											:key="index"
										>
											<div class="list-data">
												{{ item.createdTime }}
												<span style="color: #2d8cf0">{{ item.name }}</span>
											</div>
											<!--                      <div class="list-state">-->
											<!--                        <status :status="item.type" />-->
											<!--                      </div>-->
										</div>
									</div>
								</div>
							</div>
						</div>
						<project @details="details"> </project>
					</div>
				</div>
			</div>
		</el-scrollbar>
		<NoticePreview
			:visible="noticeViewVisible"
			@onCancel="noticeViewVisible = false"
			:typeList="noticeTypeList"
			:viewId="noticeViewId"
		></NoticePreview>
	</div>
</template>
<script>
import { mapState } from "vuex"
import api from "@/api/notice/notice"
import documentApi from "@/api/document/docfile"
import NoticePreview from "@/components/BusinessComponents/EditPreview/EditPreview.vue"
import ContainerHeader from "./components/ContainerHeader"
import message from "./components/message"
import project from "./components/project"
import status from "./components/status"
import MenuSwiperItem from "./components/MenuSwiperItem"

const { apiGetPage } = api
const { apiGetPage: apiGetDocPage } = documentApi

export default {
	name: "NavigationAll",
	components: {
		// resetDialog,
		ContainerHeader,
		// DialogMore,
		status,
		MenuSwiperItem,
		message,
		project,
		NoticePreview
	},
	data() {
		return {
			isShowPage: true,
			centers: [],
			noticeList: [],
			docList: [],
			moreDisabledM: false, // 更多按钮禁用
			moreDisabledV: false,
			datasDisabledN: false, // ‘暂无数据’是否显示
			datasDisabledD: false,
			page: {
				current: 1,
				pageSize: 10
			},
			noticeViewVisible: false,
			noticeViewId: ""
		}
	},
	computed: {
		...mapState("permission", ["systemRouter"]),
		...mapState({
			noticeTypeList() {
				return this.$store.state.enumerate.NOTICE
			}
		})
	},
	created() {
		this.centers = this.systemRouter.filter(item => item.name !== "navigation")
		this.initList()
		this.initDocList()
	},
	methods: {
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage({
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.noticeList = res.data.list
					this.datasDisabledN = !(this.noticeList.length > 0)
				}
				this.loading = false
			})
		},
		initDocList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetDocPage({
					pid: 1,
					projectId: null
				})
				if (res) {
					this.docList = res.data ? res.data.docfiles : []
					this.datasDisabledD = !(this.docList.length > 0)
				}
				this.loading = false
			})
		},
		details() {
			console.log("进行中的项目")
		},
		handleClick(sysItem) {
			this.isShowPage = false
			if (sysItem.path === "#") {
				return false
			}
			this.$router.push({ path: sysItem.path })
		},
		// 资讯详情展示
		handleNoticeViewShow(notice) {
			this.noticeViewId = notice.id
			this.noticeViewVisible = true
		}
	}
}
</script>
<style rel="stylesheet/scss" lang="less" scoped>
.nav-container {
	width: 100%;
	height: 100%;
	background-color: #f2f5f7;
}
.scrollbars {
	width: 100%;
	height: 100%;
}
.containerMain {
	min-width: 1000px;
	//overflow: hidden;
}
.containerHeaders {
	margin: 20px 20px 15px 20px;
}
.containers {
	display: flex;
	flex-direction: column;
	height: calc(~"100vh - 250px");
	margin: 0 25px;
	.bannerRow {
		display: flex;
		flex-direction: row;
		height: 190px;
		margin-bottom: 15px;
		.swiper-left {
			width: calc(~"100% - 615px");
			min-width: 600px;
			height: 100%;
			margin-right: 15px;
			border-radius: 5px;
		}
	}

	.main {
		flex-grow: 1;
		display: flex;
		flex-direction: row;
		.mines {
			min-width: 600px;
			flex-grow: 1;
			margin-right: 15px;
			.mine-main {
				height: 100%;
				border-radius: 5px;
			}
			.lines {
				width: 90%;
				height: 1px;
				margin: 0 auto;
				background-color: #f0f1f2;
			}
			.listDatas-disabled {
				text-align: center;
				line-height: 200px;
				font-size: 14px;
				color: #999999;
			}
			.listDatas {
				//height: 370px;
				.list {
					display: flex;
					justify-content: space-between;
					height: 40px;
					line-height: 40px;
					width: 90%;
					margin: 0 auto;
					cursor: pointer;
					.list-data {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						font-size: 14px;
						color: #333333;
					}
					.list-state {
						width: 70px;
						font-size: 12px;
						flex-shrink: 0;
						.stateDoat {
							position: absolute;
							top: 17px;
							right: 38px;
							width: 5px;
							height: 5px;
							border-radius: 50%;
							margin-right: 5px;
							background-color: #28bc5c;
						}
						.stateDoats {
							position: absolute;
							top: 17px;
							right: 38px;
							width: 5px;
							height: 5px;
							border-radius: 50%;
							margin-right: 5px;
							background-color: #f56c6c;
						}
					}
				}
			}
			.mine-div {
				display: flex;
				justify-content: space-between;
				.title {
					width: 200px;
					color: #333333;
					font-size: 18px;
					line-height: 28px;
					margin: 14px 0;
					font-weight: 600;
				}
				.icon {
					margin-left: 15px;
					margin-right: 10px;
					color: #999;
				}
				.more {
					width: 30px;
					line-height: 56px;
					margin-right: 25px;
					cursor: pointer;
					color: #2d8cf0;
					font-size: 14px;
				}
				.disabledMore {
					width: 30px;
					margin-right: 25px;
					cursor: pointer;
					color: #999;
					font-size: 14px;
				}
			}
			.firstLine {
				display: flex;
				justify-content: space-between;
				height: calc(~"100% - 20px");
				.myMessage,
				.followUp {
					background-color: #ffffff;
				}
			}
			.secondLine {
				display: flex;
				justify-content: space-between;
				.approval,
				.subscribe {
					background-color: #ffffff;
				}
			}
		}
		.mine-main {
			height: 484px;
			flex-shrink: 0;
			width: calc(~"50% - 8px");
		}
	}
}

.avatar-container {
	margin-right: 10px;
	color: #eeeeee;
	.avatar-wrapper {
		position: relative;

		.user-avatar {
			cursor: pointer;
			vertical-align: middle;
			width: 28px;
			height: 28px;
			border-radius: 50%;
		}
		.user-name {
			font-size: 14px;
			margin-left: 5px;
		}
		.el-icon-arrow-down {
			margin-left: 5px;
			font-size: 12px;
		}
	}
}
//自定义布局
.sidebarIconStyleB {
	.sidebarIconStyleB-title {
		.svg-icon {
			width: 1em !important;
		}
		span {
			display: inline-block;
			font-size: 15px !important;
			margin-left: 0;
		}
	}
	.sidebarIconStyleB-content-title {
		display: flex;
		justify-content: center;
		align-items: center;
		.content-title {
			font-size: 28px;
			padding-top: 0;
		}
		font-weight: 600;
		.svg-icon {
			width: 6.5em !important;
			height: 5em !important;
		}
	}
}
</style>
<style>
.scrollbars .el-scrollbar__wrap {
	overflow-x: hidden;
}
</style>
