<template>
	<Modal
		title="注册信息"
		:value="visible"
		:mask-closable="false"
		@on-cancel="onCancel"
		width="760"
	>
		<div class="regCon">
			<Spin fix v-if="loading"></Spin>
			<Form ref="form" :model="formData" :rules="rules">
				<div class="title">所属组织</div>
				<Row :gutter="32" class="mrgin0">
					<Col span="12">
						<FormItem label="类型：">
							{{ formData.orgTypeName }}
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="全称：">
							{{ formData.orgName }}
						</FormItem>
					</Col>
				</Row>
				<div class="title">所属部门</div>
				<Row :gutter="32" class="mrgin0">
					<Col span="12">
						<FormItem label="名称：">
							{{ formData.deptName }}
						</FormItem>
					</Col>
				</Row>
				<div class="title">用户信息</div>
				<Row :gutter="32" class="mrgin0">
					<Col span="12">
						<FormItem label="邮箱地址：">
							{{ formData.userName }}
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="真实姓名：">
							{{ formData.realName }}
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="性别：">
							{{ formData.sexName }}
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="手机号：">
							{{ formData.phone }}
						</FormItem>
					</Col>
					<Col span="24">
						<FormItem label="用户角色：">
							{{
								formData.roleNameList ? formData.roleNameList.join("；") : "--"
							}}
						</FormItem>
					</Col>
					<Col span="24">
						<FormItem prop="status" label="审批结果：">
							{{ formData.status === "1" ? "通过" : "不通过" }}
						</FormItem>
					</Col>
					<Col span="24">
						<FormItem prop="reason" label="备注：">
							{{ formData.reason || "--" }}
						</FormItem>
					</Col>
				</Row>
			</Form>
		</div>
		<div slot="footer">
			<Button @click="onCancel">关闭</Button>
		</div>
	</Modal>
</template>
<script>
import { getRegDetail } from "@/api/user/register"

export default {
	name: "RegisterHistory",
	props: ["visible", "id"],
	data() {
		return {
			loading: false,
			formData: {
				status: ""
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		}
	},
	watch: {
		visible() {
			if (this.visible && this.id) {
				this.$refs.form.resetFields()
				this.$asyncDo(async () => {
					this.loading = true
					const res = await getRegDetail({ taskId: String(this.id) })
					if (res) {
						this.formData = {
							...res.data,
							status: String(res.data.status)
						}
					}
					this.loading = false
				})
			}
		}
	}
}
</script>
<style lang="less" scoped>
.regCon {
	height: 600px;
	overflow: auto;
}
.title {
	font-size: 18px;
	font-weight: bold;
	height: 40px;
	line-height: 40px;
}
.mrgin0 {
	margin-left: 0 !important;
	margin-right: 0 !important;
}
</style>
