<template>
	<div class="project">
		<div class="project-title">
			<div>
				<h3><svg-icon class="icon" icon-class="project"></svg-icon>日历</h3>
			</div>
			<p class="lines"></p>
		</div>
		<div style="margin: 0 15px 15px">
			<CtmsCalendar :height="372" @onDateChange="handleCalendarDateChange">
				<template slot="dateCellRender" slot-scope="date">
					<Poptip
						trigger="hover"
						v-if="renderCalendarList(date).toDoList.length"
						placement="bottom-start"
						title="待办事项"
					>
						<img
							class="calendar-status"
							src="../../../assets/images/workbench/calendar4.png"
						/>
						<ul slot="content">
							<li
								v-for="(item, index) in renderCalendarList(date).toDoList"
								:key="`${index}_${item.id}`"
							>
								{{ index + 1 }}.{{ item.procInstName }}
							</li>
						</ul>
					</Poptip>
					<Poptip
						trigger="hover"
						v-if="renderCalendarList(date).unStartMeetingList.length"
						placement="bottom-start"
						title="未开始会议"
					>
						<img
							class="calendar-status"
							src="../../../assets/images/workbench/calendar0.png"
						/>
						<div slot="content" class="un-start-meeting">
							<h3 class="meeting-title">
								{{ renderCalendarList(date).unStartMeetingList[0].title }}
							</h3>
							<p>
								时间：{{
									renderCalendarList(
										date
									).unStartMeetingList[0].startTime.slice(10, 16)
								}}
								-
								{{
									renderCalendarList(date).unStartMeetingList[0].endTime.slice(
										10,
										16
									)
								}}
							</p>
							<p>
								地点：{{
									renderCalendarList(date).unStartMeetingList[0].address
								}}
							</p>
						</div>
					</Poptip>
					<Poptip
						trigger="hover"
						v-if="renderCalendarList(date).meetingGoingList.length"
						placement="bottom-start"
						title="进行中会议"
					>
						<img
							class="calendar-status"
							src="../../../assets/images/workbench/calendar1.png"
						/>
						<ul slot="content">
							<li
								v-for="(item, index) in renderCalendarList(date)
									.meetingGoingList"
								:key="`item_${item.id}`"
							>
								{{ index + 1 }}.{{ item.title }}
								{{ item.startTime.slice(10, 16) }} -
								{{ item.endTime.slice(10, 16) }}
							</li>
						</ul>
					</Poptip>
				</template>
			</CtmsCalendar>
		</div>
	</div>
</template>

<script>
import "@/views/Workbench/style.less"
import api from "@/api/workbench/workbench"
import meetingApi from "@/api/meeting/meeting"
import moment from "moment"

const { getToDoList } = api
const { apiMyMeetingCalendarList } = meetingApi
const dateFormat = "YYYY-MM-DD"

export default {
	name: "Project",
	props: {},
	data() {
		return {
			toDoVisible: false,
			doneVisible: false,
			moreVisible: false,
			listType: "todo",
			noticeLoading: false,
			meetingLoading: false,
			noticeListData: [],
			meetingListData: [],
			meetingViewId: "",
			meetingViewVisible: false,
			noticeViewId: "",
			noticeViewVisible: false,
			applyVisible: false,
			calendarMeetingListData: [],
			ToDoCalendarListData: []
		}
	},
	created() {
		const { startTime, endTime } = this.getSearchTime(moment())
		this.getMyMeetingCalendarList(startTime, endTime)
		this.getToDoCalendarList(startTime.slice(0, 10), endTime.slice(0, 10))
	},
	mounted() {},
	methods: {
		// 获取待办日历
		getToDoCalendarList(startDate, endDate) {
			this.$asyncDo(async () => {
				const res = await getToDoList({
					pageNum: 1,
					pageSize: 9999,
					status: 1,
					startDate,
					endDate
				})
				if (res) {
					this.ToDoCalendarListData = res.data.list
				}
			})
		},
		// 获取会议日历
		getMyMeetingCalendarList(startTime, endTime) {
			this.$asyncDo(async () => {
				const res = await apiMyMeetingCalendarList({
					startTime,
					endTime
				})
				if (res) {
					this.calendarMeetingListData = res.data
				}
			})
		},
		// 修改日历日期
		handleCalendarDateChange(date) {
			const { startTime, endTime } = this.getSearchTime(date)
			this.getMyMeetingCalendarList(startTime, endTime)
			this.getToDoCalendarList(startTime.slice(0, 10), endTime.slice(0, 10))
		},
		// 获取开始结束时间
		getSearchTime(date) {
			const newDate = moment(`${date.format("YYYY-MM")}-01`)
			return {
				startTime: `${newDate.format(dateFormat)} 00:00:00`,
				endTime: `${newDate.add(1, "months").subtract(1, "days").format(dateFormat)} 23:59:59`
			}
		},
		// 渲染日历待办列表
		renderCalendarList(date) {
			const toDoList = []
			const unStartMeetingList = []
			const meetingGoingList = []
			if (this.calendarMeetingListData.length) {
				this.calendarMeetingListData.forEach(item => {
					if (item.startTime.slice(0, 10) === date.format("YYYY-MM-DD")) {
						switch (item.status) {
							case 0:
								unStartMeetingList.push(item)
								break
							case 1:
								meetingGoingList.push(item)
								break
							default:
								break
						}
					}
				})
			}
			if (this.ToDoCalendarListData.length) {
				this.ToDoCalendarListData.forEach(item => {
					if (item.createdTime.slice(0, 10) === date.format("YYYY-MM-DD")) {
						toDoList.push(item)
					}
				})
			}
			return {
				toDoList,
				unStartMeetingList,
				meetingGoingList
			}
		}
	}
}
</script>

<style lang="less" scoped>
.project {
	width: 613px;
	background-color: #ffffff;
	border-radius: 5px;
	height: calc(~"100% - 20px");
	flex-shrink: 0;
	.icon {
		width: 3em;
		color: #999;
	}
	.project-title {
		position: relative;
		height: 45px;
		line-height: 28px;
		div {
			h3 {
				white-space: nowrap;
				margin: 14px 0;
				font-size: 18px;
				color: #333333;
				font-weight: 600;
			}
		}
		.lines {
			position: absolute;
			top: 42px;
			left: 0;
			margin-left: 50%;
			transform: translate(-50%, -50%);
			width: 90%;
			height: 1px;
			background-color: #f0f1f2;
		}
	}
	.el-scrollbar {
		.scrollbar {
			width: 90%;
			height: 200px;
			margin: 0 auto;
			padding: 10px 0;
			li {
				display: flex;
				height: 46px;
				line-height: 46px;
				font-size: 14px;
				color: #666666;
				.span {
					color: #333333;
				}
				.number {
					flex: 1;
				}
				.datas {
					width: 150px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					flex: 2;
				}
				.operator {
					white-space: nowrap;
					flex: 1;
					color: #2d8cf0;
					text-align: center;
					cursor: pointer;
				}
			}
		}
	}
}
</style>
