<template>
	<Modal
		class="apply-list-modal"
		:value="visible"
		width="1150"
		:fullscreen="fullscreen"
		:scrollable="true"
		:mask-closable="false"
		:footer-hide="true"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<div slot="header" class="modal-header">
			<h2>申请列表</h2>
			<a
				href="javascript:;"
				class="fullscreen"
				@click="fullscreen = !fullscreen"
			>
				<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
					<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
					<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
				</Tooltip>
			</a>
		</div>
		<CtmsDataGrid
			:height="gridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		></CtmsDataGrid>
		<Approval
			:visible="applyVisible"
			:projectId="projectId"
			:approveNo="approveNo"
			type="view"
			@onCancel="applyVisible = false"
			:procInstId="procInstId"
		></Approval>
	</Modal>
</template>

<script>
import api from "@/api/workbench/workbench"
import { mapState } from "vuex"
import Approval from "@approval"

const { getApplyProgress } = api

export default {
	name: "ApplyList",
	components: {
		Approval
	},
	props: ["visible"],
	data() {
		return {
			loading: false,
			fullscreen: true,
			listData: [],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			columns: [
				{
					title: "项目名称",
					key: "projectName",
					nowrap: true
				},
				{
					title: "主要研究者(PI)",
					key: "piName"
				},
				{
					title: "流程名称",
					key: "name"
				},
				{
					title: "申请时间",
					key: "startTime"
				},
				{
					title: "状态",
					key: "status"
				},
				{
					title: "操作",
					key: "action",
					width: 120,
					renderButton: () => {
						const actionArr = [
							{
								label: "详情",
								on: {
									click: this.handleApplyShow
								}
							}
						]
						return actionArr.filter(item => !!item)
					}
				}
			],
			applyVisible: false,
			procInstId: "",
			projectId: "",
			approveNo: ""
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.initList()
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 200
		}),
		gridHeight() {
			return this.fullscreen
				? document.body.clientHeight - 100
				: this.dataGridHeight
		},
		isShenzhen() {
			return this.$store.state.environment === "shenzhenrenmin"
		}
	},
	created() {
		if (this.isShenzhen) {
			this.columns.splice(1, 0, {
				title: "项目编号",
				key: "projectApproveNo"
			})
			this.columns.splice(
				3,
				0,
				{
					title: "申办方",
					key: "sponsorName"
				},
				{
					title: "CRO",
					key: "croName"
				}
			)
			this.columns = [...this.columns]
		}
	},
	methods: {
		// 初始化列表
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await getApplyProgress({
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.listData = res.data.list.map(item => {
						Object.assign(item, {
							status: item.endTime ? "已完成" : "进行中"
						})
						return item
					})
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		// 关闭
		onCancel() {
			this.$emit("onCancel")
			// this.fullscreen = false;
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 查看流程详情
		handleApplyShow({ row }) {
			this.procInstId = row.id
			this.projectId = row.tenantId
			this.approveNo = row.projectApproveNo
			this.$nextTick(() => {
				this.applyVisible = true
			})
		}
	}
}
</script>

<style lang="less">
.apply-list-modal {
	.modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
}
</style>
