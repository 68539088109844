var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project"},[_c('div',{staticClass:"project-title"},[_c('div',[_c('h3',[_c('svg-icon',{staticClass:"icon",attrs:{"icon-class":"project"}}),_vm._v("日历")],1)]),_c('p',{staticClass:"lines"})]),_c('div',{staticStyle:{"margin":"0 15px 15px"}},[_c('CtmsCalendar',{attrs:{"height":372},on:{"onDateChange":_vm.handleCalendarDateChange},scopedSlots:_vm._u([{key:"dateCellRender",fn:function(date){return [(_vm.renderCalendarList(date).toDoList.length)?_c('Poptip',{attrs:{"trigger":"hover","placement":"bottom-start","title":"待办事项"}},[_c('img',{staticClass:"calendar-status",attrs:{"src":require("../../../assets/images/workbench/calendar4.png")}}),_c('ul',{attrs:{"slot":"content"},slot:"content"},_vm._l((_vm.renderCalendarList(date).toDoList),function(item,index){return _c('li',{key:`${index}_${item.id}`},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(index + 1)+"."+_vm._s(item.procInstName)+"\n\t\t\t\t\t\t")])}),0)]):_vm._e(),(_vm.renderCalendarList(date).unStartMeetingList.length)?_c('Poptip',{attrs:{"trigger":"hover","placement":"bottom-start","title":"未开始会议"}},[_c('img',{staticClass:"calendar-status",attrs:{"src":require("../../../assets/images/workbench/calendar0.png")}}),_c('div',{staticClass:"un-start-meeting",attrs:{"slot":"content"},slot:"content"},[_c('h3',{staticClass:"meeting-title"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.renderCalendarList(date).unStartMeetingList[0].title)+"\n\t\t\t\t\t\t")]),_c('p',[_vm._v("\n\t\t\t\t\t\t\t时间："+_vm._s(_vm.renderCalendarList(
									date
								).unStartMeetingList[0].startTime.slice(10, 16))+"\n\t\t\t\t\t\t\t-\n\t\t\t\t\t\t\t"+_vm._s(_vm.renderCalendarList(date).unStartMeetingList[0].endTime.slice(
									10,
									16
								))+"\n\t\t\t\t\t\t")]),_c('p',[_vm._v("\n\t\t\t\t\t\t\t地点："+_vm._s(_vm.renderCalendarList(date).unStartMeetingList[0].address)+"\n\t\t\t\t\t\t")])])]):_vm._e(),(_vm.renderCalendarList(date).meetingGoingList.length)?_c('Poptip',{attrs:{"trigger":"hover","placement":"bottom-start","title":"进行中会议"}},[_c('img',{staticClass:"calendar-status",attrs:{"src":require("../../../assets/images/workbench/calendar1.png")}}),_c('ul',{attrs:{"slot":"content"},slot:"content"},_vm._l((_vm.renderCalendarList(date)
								.meetingGoingList),function(item,index){return _c('li',{key:`item_${item.id}`},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(index + 1)+"."+_vm._s(item.title)+"\n\t\t\t\t\t\t\t"+_vm._s(item.startTime.slice(10, 16))+" -\n\t\t\t\t\t\t\t"+_vm._s(item.endTime.slice(10, 16))+"\n\t\t\t\t\t\t")])}),0)]):_vm._e()]}}])})],1)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }