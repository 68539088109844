<template>
	<div class="message-wrap">
		<!--待办、已办、申请-->
		<Row class="work-wrap">
			<Col span="8" class="work-type">
				<div class="actions" @click="toDoVisible = true">
					<!--          <CtmsIcon type="icon-daichuli" :size="28"></CtmsIcon>-->
					<div>
						<p class="work-type__num">{{ allToDoNum }}</p>
						<p class="work-type__label">待办事项</p>
					</div>
				</div>
			</Col>
			<Col span="8" class="work-type">
				<div class="actions" @click="doneVisible = true">
					<!--          <CtmsIcon type="icon-yibanshixiang" :size="28"></CtmsIcon>-->
					<div>
						<p class="work-type__num">{{ doneNum }}</p>
						<p class="work-type__label">已办事项</p>
					</div>
				</div>
			</Col>
			<Col span="8" class="work-type">
				<div class="actions" @click="applyVisible = true">
					<!--          <CtmsIcon type="icon-wodeshenqing" :size="28"></CtmsIcon>-->
					<div>
						<p class="work-type__num">{{ applyNum }}</p>
						<p class="work-type__label">我的申请</p>
					</div>
				</div>
			</Col>
		</Row>
		<ToDoList
			:visible="toDoVisible"
			type="all"
			@onOk="handleOnOk"
			@onCancel="toDoVisible = false"
		></ToDoList>
		<DoneList :visible="doneVisible" @onCancel="doneVisible = false"></DoneList>
		<ApplyList
			:visible="applyVisible"
			@onCancel="applyVisible = false"
		></ApplyList>
	</div>
</template>

<script>
import "./style.less"
import ToDoList from "@/views/Workbench/components/ToDoList.vue"
import DoneList from "@/views/Workbench/components/DoneList.vue"
import ApplyList from "@/views/Workbench/components/ApplyList.vue"
import { mapState } from "vuex"

export default {
	name: "message",
	props: {},
	components: {
		ToDoList,
		DoneList,
		ApplyList
	},
	data() {
		return {
			toDoVisible: false,
			doneVisible: false,
			applyVisible: false
		}
	},
	computed: {
		...mapState("process", ["allToDoNum", "doneNum", "applyNum"])
	},
	created() {
		this.$store.dispatch("process/getAllToDoNum")
		this.$store.dispatch("process/getDoneNum")
		this.$store.dispatch("process/getApplyNum")
	},
	methods: {
		// 内部完成代办时，刷新代办列表
		handleOnOk() {
			this.$store.dispatch("process/getAllToDoNum")
			this.$store.dispatch("process/getDoneNum")
		}
	}
}
</script>
