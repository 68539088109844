<template>
	<div class="containerHeader">
		<div class="welcome"></div>
		<div class="text"></div>
		<div class="time">
			{{ nowDate }}
		</div>
	</div>
</template>

<script>
export default {
	name: "ContainerHeader",

	data() {
		return {
			nowDate: "", // 当前日期
			timer: null
		}
	},
	created() {
		this.formatDate()
		this.timer = setInterval(this.formatDate, 1000)
	},
	// 销毁定时器
	beforeDestroy() {
		clearInterval(this.timer) // 在Vue实例销毁前，清除时间定时器
	},
	methods: {
		formatDate() {
			const date = new Date()
			const year = date.getFullYear() // 年
			const month = date.getMonth() + 1 // 月
			const day = date.getDate() // 日
			const week = date.getDay() // 星期
			const weekArr = [
				"星期日",
				"星期一",
				"星期二",
				"星期三",
				"星期四",
				"星期五",
				"星期六"
			]
			let hour = date.getHours() // 时
			hour = hour < 10 ? `0${hour}` : hour // 如果只有一位，则前面补零
			let minute = date.getMinutes() // 分
			minute = minute < 10 ? `0${minute}` : minute // 如果只有一位，则前面补零
			let second = date.getSeconds() // 秒
			second = second < 10 ? `0${second}` : second // 如果只有一位，则前面补零
			this.nowDate = `${year}年${month}月${day}日  ${weekArr[week]}  ${hour}:${minute}:${second} `
		}
	}
}
</script>

<style lang="less" scoped>
.containerHeader {
	display: flex;
	justify-content: space-between;
	position: relative;
	height: 151px;
	background: url("~@/assets/login_images/headers.png") no-repeat;
	background-size: 100% 100%;
	.welcome {
		flex-shrink: 0;
		width: 45%;
		height: 99px;
		position: absolute;
		right: 25px;
		top: 30px;
		background: url("~@/assets/login_images/welcome.png") no-repeat;
		background-size: 100% 100%;
		z-index: 999;
	}
	.text {
		width: 700px;
		height: 39px;
		position: absolute;
		left: 37px;
		top: 50px;
		background: url("~@/assets/login_images/headersWelcome.png") no-repeat;
		background-size: 100% 100%;
		z-index: 999;
	}
	.time {
		position: absolute;
		bottom: 25px;
		left: 45px;
		width: 270px;
		height: 25px;
		line-height: 23px;
		font-family: DINPro-Medium;
		font-size: 18px;
		color: #333333;
	}
}
</style>
